// src/HomePage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css'; // Import your CSS file

const HomePage = () => {
  const navigate = useNavigate();

  const navigateToDaily = () => {
    navigate('/daily');
  };

  const navigateToWeekly = () => {
    navigate('/weekly');
  };
  return (
    <div className="home-container">
      <div className="button-container">
        <button className="button" onClick={navigateToDaily}>
          Daily Rank
        </button>
        <button className="button" onClick={navigateToWeekly}>
          Weekly Rank
        </button>
      </div>
    </div>
  );
};

export default HomePage;
