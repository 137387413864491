// src/components/Header.js
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Tabs, Tab } from '@mui/material';

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;

    const handleChange = (event, newValue) => {
        navigate(newValue);
    };

    return (
        <AppBar position="static" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }} onClick={() => navigate('/')} component="span" >
                    FHX Fata App
                </Typography>
                <Tabs
                    value={currentPath}
                    onChange={handleChange}
                    textColor="inherit"
                    indicatorColor="secondary"
                >
                    <Tab label="Home" value="/" />
                    <Tab label="Daily Rank" value="/daily" />
                    <Tab label="Weekly Rank" value="/weekly" />
                </Tabs>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
