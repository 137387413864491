// src/DailyRankPage.js
import React from 'react';
import DataTable from './components/DataTable';
import './DailyRankPage.css';

const DailyRankPage = () => {
  const columns = [
    { Header: 'Rank', accessor: 'id', width: 100 },
    { Header: 'Name', accessor: 'name', width: 500 },
    { Header: 'Level', accessor: 'level', width: 100 },
    { Header: 'Experience', accessor: 'experience', width: 250 },
    { Header: 'Class', accessor: 'className', width: 150 },
    { Header: 'Guild', accessor: 'guildName', width: 150 },
  ];

  return (
    <div className="page-container">
      <div className="table-container">
        <h1 className="page-header">Daily Rank</h1>
        <h2 className="page-explanation">Resets every 5am CET and shows Last 24 Hours of Exp</h2>
        <DataTable apiUrl="https://uralsaluk.com/api/v0/fhxRank/getDailyRank" columns={columns} />
      </div>
    </div>
  );
};

export default DailyRankPage;