// src/components/DataTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import './DataTable.css'; // Import the CSS file

const DataTable = ({ apiUrl, columns }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(apiUrl)
      .then(response => {

        const dataWithId = response.data.dataList.map((item, index) => ({
          ...item,
          id: index + 1

        }))

        setData(dataWithId);

      })
      .catch(error => console.error('Error fetching data:', error));
  }, [apiUrl]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <div className="table-container">
      <table {...getTableProps()} className="styled-table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};


export default DataTable;