// src/components/Layout.js
import React from 'react';
import Header from './Header';

const Layout = ({ children }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Header />
            <main style={{ flexGrow: 1, overflow: 'auto' }}>
                {children}
            </main>
        </div>
    );
};

export default Layout;
